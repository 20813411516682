















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'AudioPodcast',
  props: {
    podcastUrl: {
      type: String,
    }
  },
});
